@font-face {
  font-family: 'Outfit';
  src: url('./files/Outfit-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url(./files/Outfit-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url(./files/Outfit-Light.ttf);
  font-weight: 300;
  font-style: normal;
}
